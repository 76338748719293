.lobby {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  .lobby-wrapper {
    height: 25rem;
    max-height: 90%;
    width: 100%;
    max-width: 22.5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;

    .menu-logo {
      font:
        bold 5.625rem "dekiru",
        corbel,
        sans-serif;
      margin: 0;
      height: 1em;
      user-select: none;

      @media screen and (max-width: 500px) {
        font-size: 4rem;
      }
    }

    .loading {
      width: 2em;
    }

    .copyright {
      font-size: 0.75rem;
    }
  }
}
