@import "../../styles/colors";

.loginForm {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  padding: 0.625em;

  .row {
    padding: 0.625em;
    display: flex;
    justify-content: center;
    align-items: center;

    &.hidden {
      display: none;
    }

    &.buttonRow {
      padding: 0 0.625em;

      button:not(last-child) {
        margin-right: 0.5rem;
      }
    }

    .label {
      display: inline-block;
      width: 5.5em;
    }

    .input {
      padding: 0.5em;
      font-size: 0.888em;
      border: 1px solid $borderColor;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      border-radius: 0.25em 0;

      &:disabled {
        color: $borderColor;
        background-color: $cellDisabledColor;
      }
    }

    .formError {
      font-size: 0.777em;
      color: $errorColor;
      font-weight: 600;

      &.hidden {
        visibility: hidden;
      }
    }

    .formSwitch {
      text-decoration: underline;
      font-size: 0.875em;
      border: 0;
      background: none;

      &.disabled {
        cursor: default;
        color: $borderColor;
      }

      &:hover:not(.disabled) {
        color: $pinkColor;
      }
    }
  }
}
