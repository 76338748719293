.sudoku {
  border: 2px solid black;
  border-collapse: collapse;

  tr {
    &:nth-of-type(3n) td {
      border-bottom: 2px solid black;
    }

    td {
      border: 1px solid black;
      padding: 0;
      width: 2.1875em;
      height: 2.1875em;
      overflow: hidden;

      &:nth-of-type(3n) {
        border-right: 2px solid black;
      }
    }
  }
}
