.column-container {
  display: flex;
  flex-flow: row nowrap;

  &.login-btns {
    margin: 0.5rem 0;

    button {
      width: 4.375rem;
    }
  }

  .column {
    padding: 0 0.625rem;
    display: flex;
    flex-flow: column nowrap;
  }

  .separator {
    font-size: 1.25rem;
    margin-right: 0.75rem;
    margin-left: 0.5rem;
  }
}
