@import "../../styles/colors";

.game-buttons {
  display: flex;
  margin-top: 1.25em;
  border: 1px solid;
  border-radius: 62.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    height: 2em;
    width: 3.75em;
    color: $blackColor;
    z-index: 5;
    vertical-align: middle;
    line-height: 2em;
    text-align: center;
    border-radius: 50em;
    font-size: 1.25em;
  }

  .slider {
    position: absolute;
    height: 2.5em;
    width: 4.6875em;
    background-color: rgba($pinkColor, 0.6);
    border-radius: 2.625em;
    transition: 0.3s;

    &.notes-slider {
      transform: translateX(100%);
    }
  }

  @media (hover: hover) {
    &:hover {
      .slider {
        background-color: rgba($pinkColor, 0.4);
        transform: scaleX(1.1);
        transform-origin: left;

        &.notes-slider {
          transform: scaleX(1.1) translateX(90%);
          transform-origin: right;
        }
      }
    }
  }
}
