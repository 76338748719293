@import "../../styles/colors";

$height: 1.5625em;

textarea {
  text-align: center;
  padding: 0;
  resize: none;
  vertical-align: middle;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 100%;

  &:focus {
    outline: 0;
  }
}

.pencil {
  font-size: 1.4em;
  letter-spacing: 0;
  line-height: $height;
  background-color: $whiteColor;
}

.readOnly {
  font-size: 1.4em;
  letter-spacing: 0;
  line-height: $height;
  background-color: $cellDisabledColor;
  cursor: default;
}

.notes {
  font-size: 0.7em;
  letter-spacing: 0.3em;
  line-height: 1;
  background-color: $whiteColor;
}

.highlight {
  background-color: $cellHighlightedColor;
}

.error {
  line-height: calc($height - (4px * 2));
  border: 4px solid $errorColor;
}

.disabled {
  cursor: default;
}
