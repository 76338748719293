$checkboxHeight: 1.3em;

.checkbox-label {
  display: flex;
  align-items: center;
  height: calc($checkboxHeight + 2px);
  margin-bottom: 0.5em;

  input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    margin: 0;
    padding: 0;
  }

  span {
    position: relative;
    padding-left: 2em;
    height: 100%;
    line-height: 1.5;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
    }

    &::before {
      content: "";
      height: $checkboxHeight;
      width: $checkboxHeight;
      border: 1px solid;
      left: 0;
      border-radius: 50em;
    }

    &::after {
      content: none;
      height: 0.375em;
      width: 0.5625em;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: calc(($checkboxHeight - 0.5625em) / 2);
      top: calc($checkboxHeight / 4);
    }
  }

  &.checked {
    span {
      &::after {
        content: "";
      }
    }
  }
}
