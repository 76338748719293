.game {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.game-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;

  @media (orientation: portrait) and (max-width: 400px) {
    font-size: calc(0.5rem + 1.2vw);
  }

  @media (orientation: landscape) and (max-height: 450px) {
    font-size: calc(0.5rem + 1.1vh);
  }
}
