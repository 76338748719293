@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon {
  display: block;
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;

  &.spinner {
    animation: rotating 2s ease-out infinite;
  }
}
